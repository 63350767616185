
.main {
  position: relative;
  height: 100%;
  background: linear-gradient(334deg, #1C2B5F -0.39%, rgba(0, 14, 137, 0.13) 100%);
  background-color: black;
  overflow: hidden;
  margin: auto;
}

/* .sash {
  position: absolute;
  width: 35vw;
  height: 53rem;
  top: 0;
  left: 0;
  transform-origin: top left;
  transform: skewY(65deg);
  background: #6CA2FF;
  opacity: 0.35;
  filter: blur(500px);
} */

.container {
  max-width: 1500px;
  margin: auto;
  padding-left: var(--page-padding);
  padding-right: var(--page-padding);
}
