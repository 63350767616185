@import url('https://fonts.googleapis.com/css?family=Inter:400,600,700');
@import url('https://fonts.googleapis.com/css?family=Spline+Sans+Mono:300,600,700');

@font-face {
  font-family: 'Alien Robot';
  src: local('AlienRobot'),
       url('./fonts/alien_robot/AlienRobot.ttf') format('truetype');
  font-weight: 400; /* Adjust as needed */
  font-style: normal;
}

@font-face {
  font-family: 'Alien Robot';
  src: local('AlienRobot'),
       url('./fonts/alien_robot/AlienRobot.otf') format('opentype');
  font-weight: 400; /* Adjust as needed */
  font-style: normal;
}

@font-face {
  font-family: 'Alien Encounters Solid';
  src: local('Alien Encounters Solid'),
       url('./fonts/alien_encounters/Alien-Encounters-Solid-Regular.ttf') format('truetype');
  font-weight: 400; /* Adjust as needed */
  font-style: normal;
}

@font-face {
  font-family: 'Alien Encounters Regular';
  src: local('Alien Encounters Regular'),
       url('./fonts/alien_encounters/Alien-Encounters-Bold.ttf') format('truetype');
  font-weight: 400; /* Adjust as needed */
  font-style: normal;
}


html {
  font-size: 16px;
  background: black;
  margin: 0;
} /*16px*/

body {
  font-family: 'Inter', 'arial';
  font-weight: 400;
  /* line-height: 1.75; */
  margin: 0;
}

h3 {font-size: 1.953rem;}

h4 {font-size: 1.563rem;}

h5 {font-size: 1.25rem;}

small, .text_small {font-size: 0.8rem;}

img {
  -webkit-user-drag: none !important;
  user-select: none !important;
  -moz-user-select: none !important;
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
}

@media only screen and (max-width: 499px) {

}


/* Mobile */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .header-app-button {
    height: 2.5rem !important;
  }
}

@media only screen and (min-width: 655px) and (max-width: 767px) {
  .header-app-button {
    height: 2.5rem !important;
  }
}


/* Past mobile */
@media only screen and (min-width: 768px) {
  .header-app-button {
    height: 2.5rem !important;
  }
}




/* Tablets */
@media only screen and (min-width: 768px) and (max-width: 960px) {
  .header-app-button {
    height: 2.5rem !important;
  }
}



/* Laptops */
@media only screen and (min-width: 961px) and (max-width: 1211px) {
  .header-app-button {
    height: 2.25rem !important;
  }

  html {
    /* 17px before */
    font-size: 18px; 
  }

}



/* Desktop and Wide screen */
@media only screen and (min-width: 1212px) {
  .header-app-button {
    height: 2rem !important;
  }

  html {
    font-size: 21px;
  }

}



:root {
  /* global colors */
  --background-gradient-blue: #000335;
  --components-primary-blue: #007FFF;
  --text-primary-white: #FFFFFF;
  --text-secondary-gray: #C4C4C4;
  --overlay-modal-black: var(--chakra-colors-blackAlpha-800);

  /* left && right page padding */
  --page-padding: 1.5rem;

  /* menu items padding */
  --menu-item-left-padding: 1rem;

  /* button padding */
  --button-px: 1rem;
  --button-py: 0.1rem;

  /* button left margin */
  --button-lm: 0.5rem;

  /* header margin font line-height */
  --header-font-weight: 600;
  --header-line-height: 1.1;

  /* h1 attributes */
  --h1-font-size: 1.7rem;

  /* h2 attributes */
  --h2-font-size: 1.25rem;

  /* p attributes */
  --p-font-size: 1rem;

  /* p2 attributes */
  --p2-font-size: 0.8rem;

  /* chevron size */
  --chevron-size: 0.4rem;

  /* grid item bottom margin */
  --grid-item-bm: 1.5rem;

  /* vertical grid spacing */
  --row-gap: 3.5rem;

  /* horizontal grid spacing */
  --column-gap: 2.5rem;

  /* section bottom margin */
  --section-bm: 6.5rem;
}
